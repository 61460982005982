//////////////////////////////////////////////////
//  Witches.Town theme
//  Originally by Alda and Sylvhem of witches.town
//  provided under the AGPL license
////////////////////////////////////////////////////

// Colors
$ui-base-color: #292929;
$ui-base-lighter-color: #d6d2bd;
$ui-primary-color: #faf9e6;
$ui-secondary-color: #fffbd3;
$ui-highlight-color: #9c9c9c;

// Columns width
div.column {
  flex-grow: 1;
}

// Fonts
@font-face {
  font-family: "Courier New", Courier, monospace;
}

* {
  font-family: "Courier New", Courier, monospace;
}

div {
  font-family: "Courier New", Courier, monospace;
}

body {
  font-family: "Courier New", Courier, monospace;
}

button {
  font-family: "Courier New", Courier, monospace;
}

// Import defaults
@import 'application';
